<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
  >
    <span>{{ title }}</span>
    <span class="text-uppercase">{{ $t('Common.Or') }}</span>
    <label @click="$refs.fileInput.click()" :class="['btn', 'btn-dark', disabled ? 'disabled' : '']">
      {{ $t('Form.SelectFile') }}
    </label>

    <div class="text-muted form-row justify-content-center">
      <span class="text-nowrap me-1">{{ $t('Form.Formats') }}: {{ fileTypes }}.</span>
      <span class="text-nowrap">{{ $t('Form.MaxSize') }} {{ maxSizeMb }} {{ $t('Form.Mb') }}.</span>
    </div>
    <input
      ref="fileInput"
      type="file"
      @change="onFileChange"
      :disabled="disabled"
      :multiple="multiple"
      :accept="fileFormats"
      class="dropzoneFile btn btn-dark"
    />
  </div>
</template>

<script>
export default {
  name: 'dropzone',
  props: {
    title: {
      type: String,
      default: 'Drop files here or click to upload',
    },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    maxSize: { type: Number },
    fileTypes: { type: String, default: '.Jpeg/.jpg/.png/.Tiff' },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    maxSizeMb() {
      return (((this.maxSize / 1024 / 1024) * 100) / 100).toFixed(1);
    },
    fileFormats() {
      let formats = this.fileTypes ? this.fileTypes.replaceAll('/', ',') : '';
      formats = formats ? formats.replaceAll('*', '/*') : '';
      return formats;
    },
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    onFileChange(e) {
      const files = e?.target?.files;
      const validFiles = [];

      if (!files.length) {
        return;
      }
      [...files].forEach((file) => {
        if (this.maxSize && file.size > this.maxSize) {
          this.$notify({
            type: 'error',
            text: file.name + this.$t('Form.BigFile', { size: this.maxSizeMb }),
          });
        } else {
          validFiles.push(file);
        }
      });

      if (this.multiple) {
        this.$emit('fileChange', files);
      } else {
        this.$emit('fileChange', files[0]);
      }

      e.target.value = null;
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  // width: 400px;
  height: 100px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  border: 2px dashed #666597;
  background-color: #fff;
  transition: 0.3s ease all;

  input {
    display: none;
  }
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
}
</style>
