export const dotize = (object) => {
    if (typeof object !== 'object') return object;

    let result = {};

    (function recurse(obj, current) {
        for(let key in obj) {
            let value = obj[key];
            let newKey = (current ? current + "." + key : key);  // joined key with dot
            if(value && typeof value === "object") {
                recurse(value, newKey);  // it's a nested object, so do it again
            } else {
                result[newKey] = value;  // it's not an object, so set the property
            }
        }
    })(object);

    return result;
}