import { dotize } from '@/helpers/object';

export const validationMixin = {
  methods: {
    handleResponseErrors(response, { values = {}, actions }) {
      if (response?.status === 422) {
        const errors = response?.data?.error?.fields;
        if (errors && typeof errors === 'object') {
          if (actions) actions.setErrors(errors);

          const dotValues = dotize(values);
          const hiddenFields = Object.keys(errors).filter((field) => !Object.keys(dotValues).includes(field));

          if (hiddenFields.length) {
            const firstErrorText = errors[hiddenFields[0]][0];

            this.$notify({
              type: 'error',
              text: firstErrorText,
            });

            return firstErrorText;
          }
        }
      } else if (response.data.error.message) {
        return response.data.error.message;
      }

      return null;
    },
  },
};
