<script>
export default {
  name: 'profile-footer',
  props: {
    disabledSave: {
      type: Boolean,
      required: false,
    },
    disabledCancel: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-3 mb-1 back-button">
      <router-link :to="{ name: 'home' }" class="btn text-muted btn-link ps-0">
        <i class="mdi mdi-arrow-left me-1"></i>
        {{ $t('Profile.BackToHome') }}
      </router-link>
    </div>
    <!-- end col -->
    <div class="col-12 col-md-9 pb-3">
      <div class="text-end d-flex justify-content-end flex-wrap">
        <slot name="buttons"></slot>
        <button
          type="button"
          class="btn btn-outline-dark btn-sm w-lg ms-2 mb-2 col-12 col-md-auto"
          :disabled="disabledCancel"
          @click="$emit('cancel')"
        >
          {{ $t('Common.Cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-dark btn-sm w-lg ms-2 mb-2 col-12 col-md-auto"
          :disabled="disabledSave"
          @click="$emit('save')"
        >
          {{ $t('Common.Save') }}
        </button>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>

<style scoped>
.back-button {
  white-space: nowrap;
}
</style>
