<template>
  <b-card class="image-card">
    <div class="d-block d-md-flex justify-content-between align-items-center">
      <div class="d-block d-md-flex align-items-center">
        <b-img :src="src" fluid class="image-card__image mr-0 me-md-3 mb-3 mb-md-0" alt="name"></b-img>
        <div class="mb-3 mb-md-0">
          <div class="image-card__name me-md-2">{{ name }}</div>
          <div class="image-card__size">{{ size }}</div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <b-button pill variant="outline-primary" class="image-card__view-btn me-2" @click="zoomed = true">{{
          $t('Common.View')
        }}</b-button>

        <div class="d-flex">
          <vue-feather
            type="download"
            stroke="#666597"
            size="20"
            class="cursor-pointer ms-2"
            @click="downloadImage"
          ></vue-feather>
          <vue-feather
            v-if="canDelete"
            type="x"
            stroke="#666597"
            size="18"
            class="cursor-pointer ms-2"
            @click="$emit('x-click')"
          ></vue-feather>
        </div>
      </div>
    </div>

    <div v-if="zoomed" class="zoom-popup" @click="zoomed = false">
      <img :src="src" :alt="name" />
    </div>
  </b-card>
</template>

<script>
import VueFeather from 'vue-feather';

export default {
  name: 'ImageCard',
  components: { VueFeather },
  props: {
    src: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoomed: false,
    };
  },
  methods: {
    downloadImage() {
      const imageURL = this.src;

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = this.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-card {
  border: 1px solid #eff2f7;
  box-shadow: 0px 0px 8px 0px rgba(56, 54, 147, 0.1);

  ::v-deep(.card-body) {
    padding: 8px 20px;
  }

  &__image {
    max-height: 40px;
    max-width: 200px;
    border-radius: 1px;
  }

  &__name {
    color: #495057;
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;
  }

  &__size {
    color: #74788d;
    font-size: 13px;
    font-weight: 400;
  }

  &__view-btn {
    padding: 2px 20px;
  }
}

.zoom-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: zoom-out;
  }
}
</style>
