<script>
import ProfileFooter from '@/components/pages/profile/profile-footer';
import ImageCard from '@/components/common/ImageCard';
import { validationMixin } from '@/mixins/form/validation';
import DropZone from '@/components/widgets/dropZone';
import Swal from 'sweetalert2';
import { authComputed } from '@/state/helpers';

const MAX_FILES = 10;

export default {
  name: 'personal-data-tab',
  mixins: [validationMixin],
  components: {
    ProfileFooter,
    DropZone,
    ImageCard,
  },
  data() {
    return {
      loading: true,
      currentApplication: {},
      files: [],
      MAX_FILES,
    };
  },
  computed: {
    ...authComputed,
    isStatusNew() {
      return this.currentApplication.status === undefined;
    },
    isStatusPending() {
      return this.currentApplication.status === 0;
    },
    isStatusApproved() {
      return this.currentApplication.status === 1;
    },
    isStatusRejected() {
      return this.currentApplication.status === 2;
    },
    isKysEditable() {
      return !this.isStatusApproved && !this.isStatusPending;
    },
    fileUploaderDisabled() {
      return !this.isKysEditable || this.files.length >= MAX_FILES;
    },
  },
  methods: {
    addFiles(files) {
      [...files].forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          this.files.push({
            file,
            base64: reader.result,
          });
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      });
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    getRemoteFile({ file }) {
      this.axios.get(`/kyc/application/file/${file.uuid}`, { responseType: 'arraybuffer' }).then((response) => {
        const headers = response.headers;
        const blob = new Blob([response.data], { type: headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        file.url = url;
      });
    },
    loadCurrentApplication() {
      this.axios
        .get('/kyc/application')
        .then(({ data }) => {
          this.currentApplication = data || {};

          if (!this.isStatusRejected) {
            this.files = data?.files.map((file) => ({ file })) || [];
            this.files.forEach((file) => this.getRemoteFile(file));
          } else {
            this.files = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelApplication() {
      Swal.fire({
        title: this.$t('Profile.Verification.CancelApplication'),
        html:
          "<div class='avatar-md mx-auto'>" +
          "<span class='avatar-title rounded-circle bg-warning bg-opacity-10 text-warning font-size-28'>" +
          "<i class='bx bx-meh'></i>" +
          ' </span>' +
          '</div>' +
          '<p class="text-muted mt-3 mb-3 font-size-14">' +
          this.$t('Profile.Verification.CancelApplicationConfirmation') +
          '</p>',
        showCloseButton: true,
        showCancelButton: true,
        padding: '0 20px 80px 20px',
        confirmButtonText: this.$t('Common.Continue'),
        cancelButtonText: this.$t('Common.Cancel'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-dark btn-sm w-lg me-2 mb-2',
          cancelButton: 'btn btn-outline-dark btn-sm w-lg me-2 mb-2',
          container: 'swal-padding',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loading = true;

          this.axios
            .delete('/kyc/application')
            .then(({ data }) => {
              this.currentApplication = data || {};
              this.$notify({
                type: 'success',
                text: this.$t('Profile.Verification.CancelApplicationSuccess'),
              });
            })
            .catch(({ response }) => {
              this.handleResponseErrors(response, {});
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    submitForm() {
      if (!this.files.length) {
        this.$notify({
          type: 'error',
          text: this.$t('Profile.Verification.SelectFilesError'),
        });

        return;
      }

      this.loading = true;

      this.axios
        .post('/kyc/application', {
          files: this.files.filter((file) => file.base64).map((file) => file.base64),
        })
        .then(({ data }) => {
          this.currentApplication = data || {};
          this.$notify({
            type: 'success',
            text: this.$t('Profile.Verification.ApplicationSent'),
          });
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, {});
        })
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.files = [];
    },
  },
  mounted() {
    this.loadCurrentApplication();

    this.$broadcasting.echo.private(`clients.${this.currentUser.id}.branding`).notification((notification) => {
      if (notification.key === 'kyc.rejected' || notification.key === 'kyc.approved') {
        this.loadCurrentApplication();
      }
    });
  },
  unmounted() {
    this.$broadcasting.echo.leave(`clients.${this.currentUser.id}.branding`);
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <b-card-text>
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div col="12">
              <b-alert :show="isStatusRejected" variant="danger">
                {{ $t('Profile.Verification.VarificationRejected') }}
              </b-alert>
            </div>

            <div class="row justify-content-between md">
              <div class="col-xxl-3 col-xl-4">
                <h4 class="card-title mb-4">{{ $t('Profile.Verification.Title') }}</h4>

                <p class="text-muted mb-4">{{ $t('Profile.Verification.FollowingDocuments') }}</p>
                <ul class="text-muted mx-2">
                  <li>{{ $t('Profile.Verification.ID') }},</li>
                  <li>{{ $t('Profile.Verification.Passport') }},</li>
                  <li>{{ $t('Profile.Verification.DriverLicense') }},</li>
                  <li>{{ $t('Profile.Verification.BankCard') }}</li>
                  <!-- <li>{{ $t('Profile.Verification.UtilityBill') }}</li> -->
                </ul>
              </div>

              <div class="col-xxl-3 col-xl-4">
                <div class="vertical-wizard wizard vertical">
                  <div class="steps">
                    <ul role="tablist">
                      <li class="first" :class="{ current: isStatusNew }">
                        <a href="#!">
                          <span class="number me-2">1.</span>
                          {{ $t('Profile.Verification.UploadDocument') }}
                        </a>
                      </li>
                      <li :class="{ current: isStatusPending }">
                        <a href="#!">
                          <span class="number me-2">2.</span>
                          {{ $t('Profile.Verification.Pending') }}
                        </a>
                      </li>
                      <li :class="{ current: isStatusApproved || isStatusRejected, rejected: isStatusRejected }">
                        <a href="#!">
                          <span class="number me-2">3.</span>
                          <span v-if="isStatusRejected">{{ $t('Profile.Verification.Rejected') }}</span>
                          <span v-else>{{ $t('Profile.Verification.Successful') }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="isStatusApproved" class="text-success mt-1">
                  {{ $t('Profile.Verification.DocumentsBeenChecked') }}
                </div>
                <div v-if="isStatusRejected" class="text-danger mt-1">
                  {{ $t('Profile.Verification.NeedUploadAgain') }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xxl-7 col-xl-9">
              <DropZone
                v-if="!isStatusApproved"
                @fileChange="addFiles"
                :disabled="fileUploaderDisabled"
                class="mb-3"
                :max-size="10 * 1024 * 1024"
                :title="$t('Form.DropFile')"
                :file-types="'.png/.jpg/.jpeg'"
                multiple
              />

              <ImageCard
                v-for="({ file, base64 }, i) in files"
                :key="i"
                :src="base64 || file.url"
                :name="file.name"
                :can-delete="isKysEditable"
                :size="`${Math.round((file.size / 1024 / 1024) * 100) / 100} MB`"
                @x-click="removeFile(i)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-card-text>

    <profile-footer
      v-if="!isStatusApproved"
      :disabled-save="isStatusPending"
      :disabled-cancel="isStatusPending"
      @save="submitForm"
      @cancel="resetForm"
    >
      <template v-slot:buttons>
        <button
          v-if="isStatusPending"
          type="button"
          class="btn btn-outline-danger btn-sm w-lg mb-2 col-12 col-md-auto"
          @click="cancelApplication"
        >
          {{ $t('Profile.Verification.CancelApplication') }}
        </button>
      </template>
    </profile-footer>
  </b-overlay>
</template>

<style scoped lang="scss">
.rejected {
  a {
    color: #ea5455 !important;
  }

  .number {
    background-color: #ea5455 !important;
    border-color: #ea5455 !important;
  }
}
</style>
